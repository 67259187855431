import React from "react";
import { Player } from '@lottiefiles/react-lottie-player';
import loadingAnimation from "./Animation.json";

const Loading = () => {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",

        }}>
            <div style={{
                width: "350px",
            }}>
                <Player
				loop
				autoplay
                    src={loadingAnimation}
                    style={{
                        width: "100%",
                        height: "auto",
                    }}
                />
            </div>
        </div>
    );
};

export default Loading;