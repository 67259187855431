import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SurveyCreatorWidget } from './SurveyCreatorWidget';
import {ContactForm } from './ContactForm';
import Loading from './Loading';
import './styles.css';
import Addsql from './Addsql';


export default function App() {
  const [loading, setLoading] = useState(true);
  const [validSurvey, setValidSurvey] = useState(false);
  const [surveyData, setSurveyData] = useState(null);
  const [segmentData, setSegmentData] = useState(null);
  const [validsegment, setValidsegment] = useState(false);
  const [contactform, setContactform] = useState(false);
  const [editcontactform, setEditcontactform] = useState(false);

  useEffect(() => {
    console.log("validsegment:", validsegment);
    console.log("segmentData:", segmentData);

    // Further down in the return statement to see their values
    console.log("validsegment in return:", validsegment);
    console.log("segmentData in return:", segmentData);
    const urlParams = new URLSearchParams(window.location.search);
    const surveyId = urlParams.get('survey_id');
    const domainId = urlParams.get('domain_id');
    const editSurvey = urlParams.get('editsurvey');
    const segmentId = urlParams.get('segment_id');
    const editId = urlParams.get('edit_segment');
    const contactform = urlParams.get('contact_form');
    const editcontactform = urlParams.get('edit_contact_form');

    const verifySurvey = async () => {

      try {
        if ((surveyId && domainId) || editSurvey) {
          const response = await fetch(`${process.env.REACT_APP_API_URL}.survey_domain`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ survey_name: surveyId || editSurvey, domain_id: domainId }),
          });

          if (response.ok) {
            const data = await response.json();
            if (data.message.success === true) {
              setSurveyData(data);
              setValidSurvey(true);
            } else {
              setValidSurvey(false);
            }
          } else {
            setValidSurvey(false);
          }
        } else {
          setValidSurvey(false);
        }
      } catch (error) {
        console.error(error);
        setValidSurvey(false);
      } finally {
        setLoading(false);
      }
    };

    const verifySegment = async () => {
      try {
        if (segmentId || editId) {
          const response = await fetch(`${process.env.REACT_APP_API_URL}.segment_confirm`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ segment_name: segmentId || editId }),
          });

          if (response.ok) {
            const data = await response.json();
            if (data.message.success === true) {
              console.log(data.message.success)
              setSegmentData(data);
              setValidsegment(true);
              setValidSurvey(false)
              setLoading(false);
            } else {
              setValidsegment(false);
            }
          }
        } else {
          setValidsegment(false);
        }
      } catch (error) {
        console.error(error);
        setValidsegment(false);
      }
    };
    const verifyContact = async () => {

      try {
        if (contactform || editcontactform) {
          const response = await fetch(`${process.env.REACT_APP_API_URL}.verify_contact_form`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ contactform: contactform || editcontactform}),
          });
  
          if (response.ok) {
            const data = await response.json();
            if (data.message.success === true) {
              setContactform(true);
            } else {
              setContactform(false);
            }
          } else {
            setContactform(false);
          }
        } else {
          setContactform(false);
        }
      } catch (error) {
        console.error(error);
        setContactform(false);
      } finally {
        setLoading(false);
      }
    };
    if (surveyId || editSurvey && domainId) {
      verifySurvey();
    }
    if (segmentId || editId) {
      verifySegment();
    }
    if (contactform || editcontactform) {
      verifyContact();
      console.log("contactform:", contactform);
    }
  }, []);


  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          {loading ? (
            <Route exact path="/">
              <Loading />
            </Route>
          ) : validSurvey ? (
            <Route exact path="/">
              <SurveyCreatorWidget surveyData={surveyData} />
            </Route>
          ) : validsegment ? (
            <Route exact path="/">
              <Addsql segmentData={segmentData} />
            </Route>
            ) : contactform  ? (
              <Route exact path="/">
                <ContactForm />
              </Route>
          ) : (
            <Route>
              <Redirect to="/" />
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                fontSize: '2rem',
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
              }}>Sorry, Page Not Found!!</div>
            </Route>
          )}
          <Route path="/">
            <div>Page not found</div>
          </Route>
        </Switch>
        <ToastContainer />
      </div>
    </BrowserRouter>
  );
}